.summaryCard {
    border-radius: 5px !important;
    background-color: #ffffff !important;
    box-shadow: none;

}

.graphCard {
    border-radius: 5px !important;
    background-color: #ffffff !important;
    box-shadow: none;

}