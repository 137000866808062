:root {
  --primary-dark: #e86531;
  --primary: #E88131;
  --primary-light: #e89f31;
  --bs-primary: #E88131;
  --secondary: #AAAAAA;
  --bs-secondary-rgb: #AAAAAA;
  --dark: #000000;
  --light-black: #324054;
  --light-dark: #484964;
  --white: #FFFFFF;
  --highlight: #F8F7FA;
  --silver: #dfdfdf;
  --success: #57E777;
  --danger: red;
  --light-bg: #f0f8ff;
}




.body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
a,
li,
ul,
span,
label,
table,
tr,
td,
thead,
tbody {
  font-family: 'Poppins', sans-serif;
}





.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.login-container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.Calendar {
  box-shadow: none !important;
  border: 0px solid #e5e5e5 !important;
  width: 100% !important;
}

.nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary) !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.activeDiv {
  font-weight: 400;
  color: var(--primary) !important;
}

.hoverr:hover {
  font-weight: 400;
  color: var(--primary) !important;

}

.nav-link.active {
  font-weight: 400;
  color: var(--primary) !important;
}


.dropdown-item.active {
  background-color: var(--highlight) !important;
  color: var(--primary) !important;
}

.dropdown-item:hover {
  background-color: var(--highlight) !important;
}


#main.dropdown-menu {
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 12px 0px 0px 12px;
  border: 0px solid transparent !important;
  margin-top: 5px !important;
  /* inset: 7px auto auto 0px !important; */
}



.list-group-item {
  font-size: 14px;
  border-color: whitesmoke !important;
}

.list-group-item:hover,
.list-group-item.active {
  color: var(--primary) !important;
  background-color: var(--highlight) !important;
}

th {
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--dark);
  vertical-align: middle !important;
  text-align: center;
  padding: 0;
  white-space: nowrap !important;
}

td {
  font-size: 0.75rem !important;
  font-weight: 400;
  vertical-align: middle;
  text-align: middle;
  color: var(--secondary);
}

#insightFilter.dropdown,
#profile-menu.dropdown {
  background: #fefefe;
  border: 1px solid #dfdfdf;
  color: black;
  padding: 5px;
}


#insightFilter.dropdown:hover,
#profile-menu.dropdown:hover {
  background: #fefefe;
  border: 1px solid #dfdfdf;
  color: black;
}

#insightFilter.dropdown {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

#insightFilter.dropdown.show {
  background: #fefefe !important;
  border: 1px solid #dfdfdf !important;
  color: black
}

#filter.dropdown {
  border: 1px solid var(--silver);
  border-radius: 20px;
}

#filter.dropdown:hover,
#profile-menu.dropdown:hover {
  background: var(--highlight);
  border: 1px solid var(--silver);
  color: var(--primary) !important;
}



#filter.dropdown.show {
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

#filter.dropdown.show>a {
  color: var(--white) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary,
.btn-outline-primary.active,
.btn-outline-primary:hover {
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
  color: var(--white) !important;
  font-size: 12px !important;
}

.btn-outline-primary {
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  font-size: 12px !important;


}

.text-secondary,
.secondary {
  color: var(--secondary) !important;
}

.text-primary,
.primary {
  color: var(--primary) !important;
}

.text-success,
.success {
  color: var(--success) !important;
}

.text-danger,
.danger {
  color: var(--danger) !important;
}

thead>tr>th:hover {
  background-color: var(--highlight) !important;
}

tbody>tr:hover,
tbody>tr:hover>td {
  background-color: var(--highlight) !important;
}

td {
  color: var(--light-dark) !important;
  font-weight: 300;
}

.pointer {
  cursor: pointer;
}

.data-point-card:hover {
  background-color: var(--highlight);
}

.data-point-card.active {
  background-color: var(--primary);
  color: var(--white);
}

.select-none {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.section-tab {
  color: var(--dark) !important;
  border-color: var(--silver) !important;
}

.section-tab:hover {
  background-color: var(--highlight) !important;
  color: var(--primary) !important;
}

.section-tab.active {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

/* .section-tab.btn-outline-light{
    border-color: var(--bs-secondary-rgb) !important;
  } */

.card-col.active>.card {
  border-color: var(--primary-light);
  background-color: var(--light-bg);
}



.it {
  /* fill:rgba(0,0,0,0.1); */

  stroke-width: 0;
  cursor: pointer;
}

.it:hover {
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.1) !important;
  stroke: rgba(0, 0, 0, 0.1) !important;
}

.itHeatmap {
  /* fill:rgba(0,0,0,0.1); */

  stroke-width: 0;
  cursor: pointer;
}

.itHeatmap:hover {
  cursor: pointer;
  fill: rgba(0, 0, 0, 0.3) !important;
  stroke: rgba(0, 0, 0, 0.1) !important;
}


.primaryColor {
  background-color: var(--primary-dark) !important;
}

.primaryLight {
  background-color: var(--primary-light) !important;
}

.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: #21aeca; */
}



.accordion-title,
.accordion-content {
  padding: 1rem;
}



@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}

.responsive-calendar {
  /* height: 500px !important; */
  position: relative;

}

.rounded-capsule {
  border-radius: 3.125rem !important
}

.badge {
  display: inline-block;
  padding: .35556em .71111em;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .badge {
    transition: none
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.badge-pill {
  padding-left: .71111em;
  padding-right: .71111em;
  border-radius: 10rem
}

.badge-primary {
  color: #fff;
  background-color: #e66d02
}

.badge-warning {
  color: #fff;
  background-color: #f5803e
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #fff;
  background-color: #f2600e
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(245, 128, 62, .5)
}

.badge {
  color: null;
}

.badge-soft-warning {
  color: #f06216 !important;
  background-color: #fde6d8
}

.bg-warning-1 {
  background-color: var(--primary-dark) !important;
}

.listGroupItem {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.fit-content {
  padding-left: 3rem !important;
}

.columnFixed {
  -ms-flex: 0 0 230px;
  flex: 0 0 230px;
  background-color: greenyellow;
}


#check:checked::after {
  background-color: var(--primary) !important;

}

.checkbox:checked:after {
  background-color: var(--primary) !important;
}

input[type="checkbox" i] :checked {
  background-color: var(--primary);

}

.backButton {

  cursor: pointer;
  padding: 1rem;
  border-radius: 5rem;

}

.backButton :hover {
  background: black;
  background-color: #dee2e6;
  color: black;
  padding: 1rem;
  border-radius: 5rem;
  left: 0;

}

.backButton.back-prev {
  left: 0;
}

.backButton.back-next {
  right: 0;
}

.fp-controlArrow {
  /* position: absolute; */

  height: 30px;
  width: 30px;
  margin-top: -15px;

  /* This value must always be half of the height - This does the same as above */
  z-index: 30;
  /* top: 28%; */
  margin-left: -15px;
  cursor: pointer;
  padding: 1rem;
  border-radius: 50rem;
  opacity: 0.71;


}

.fp-controlArrow:hover {
  background: green;
  background-color: rgba(212, 209, 209, 0.8);

}

.back_arrow {
  height: 30px;
  width: 30px;
  margin-top: -15px;
  margin-left: -15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50rem;
  opacity: 0.71;



}

.back_arrow:hover {
  background: green;
  background-color: rgba(212, 209, 209, 0.8);

}





.fz-12 {
  font-size: 0.75em !important;
}

.fz-20 {
  font-size: 1.25em !important;
}

.fz-10 {
  font-size: 0.625em !important;
}

.table-responsive {
  height: 400px;
  overflow: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}


.graphCardTwo {
  height: 3rem !important;
  width: auto !important;
  background-color: #ffffff !important;
  box-shadow: none;
  min-width: 6.4rem !important;


}

.graphCardTwo :hover {
  background-color: #dfdfdf !important;
  border-radius: 0px !important;

}



.zeroIndexBorder {
  border: none !important;
}

.graphcardTwoBorder {
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
  border-left: 1px solid#dfdfdf !important;
  border-radius: 0 !important;
}

.graphCardTwoActive {
  border-bottom: 3px solid #E88131 !important;
  border-radius: 0 !important;
}

.graphCardTwoCardBody {
  font-size: 12px !important;
  color: #909090 !important;
  white-space: nowrap;
  font-weight: 400;
  text-align: center;
  box-shadow: none !important;
  background: none !important;
  background-color: none !important;
}

.graphCardTwoNumber {
  font-size: 14px;
  font-weight: 400;
  color: var(--light-black);
  white-space: nowrap;
}

.graphCardTwo :hover .graphCardTwoCardBody {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
}

.graphCardTwo :hover .graphCardTwoNumber {
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
}



.button-contianer {
  display: flex;
  /* justify-content: space-between; */
}

.img-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  border: 5px solid #ffffff;
  border-radius: 10px;
}

.table-responsive {
  height: 100% !important;
  overflow-y: hidden;

}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 20px #fff;
  background-color: #dee2e6;
}

.table-responsive::-webkit-scrollbar {
  width: 3px !important;
  height: 5px !important;
  background-color: #dee2e6 !important;
}

.editButton {}

.editButton :hover {
  background-color: #e66d02;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
}




.tooltip>.tooltip-inner {
  font-size: 12px !important;
  background-color: #dee2e6 !important;
  color: black;
  white-space: nowrap;
}

.customTooltip ::before {
  border: 0 !important;
}

.del-button {
  position: absolute;
  top: 10px;
  right: 10px;
  /* background-color: red;
  color: white; */
  padding: 5px 10px;
  cursor: pointer;
  z-index: 1;
  /* Ensure it's above the image */
}

.camera-background {
  position: relative;

  /* background-image: url('./assets/img/image.png') !important; */
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;


}


.product-items {
  font-size: 11px !important;
  border-radius: 30px !important;
  border-color: "#d9d9d9" !important;
  align-items: center !important;
  justify-content: center !important;
  background: transparent !important;
  color: white !important;
}

.product-items:hover {
  background-color: white !important;
  color: black !important;
}

.search-input {
  position: relative;
  /* Enable positioning of the clear button */
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust right padding for better positioning */
  transform: translateY(-50%);
  /* Center the button vertically */
  cursor: pointer;
  /* Indicate clickable behavior */
  opacity: 0.4;
  /* Set initial transparency */
}

.search-input:focus+.clear-button,
.search-input:hover+.clear-button {
  opacity: 1;
  /* Increase opacity on focus or hover */
}






/* .background-container {
  background-blend-mode: multiply; 
} */