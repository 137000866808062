/* tbody { */
    /* height: calc(100vh - 510px); */
    /* height: 270px !important;

    table-layout: fixed !important;
    display: block !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  thead, tbody tr {

    display: table !important;
    width: 100% !important;
    table-layout: fixed !important;
    border-top: none;
    
  } */


  th {
    Position: Sticky;
    Top: 0;
  }  

  .table-container {
    Overflow: Auto;
    Max-height: 160px;
    Border: 1px Solid Red;
  }

  Th {
    Position: Sticky;
    Top: 0;
    Background: White;
  }

  .table-container {
    Overflow: Auto;
    Max-height: 300px;
    Border: 1px Solid #dee2e6;
  }
  
  Th,thead {
    Position: Sticky;
    Top: 0;
    Background: White;
    border-bottom: 1px solid black !important;
  }
  .header {
    background-color: rgba (256,256,256, 0.7)

  }

 