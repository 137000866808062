.main_ball {
    display: flex;
    gap: 3rem;
    flex-direction: column;
}
.status_hist {
    display: flex;
    width: fit-content;
    display: flex;
    align-items: center;
}
.main_connection {
    display: flex;
    gap: 20rem;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
}
.tim {
    background-color: whitesmoke;
    font-size: 15px;
    position: absolute;
    top: -20px;
    font-size: 10px;
    color: black;
    width: max-content;
    padding-left: 10px;
    padding-right: 10px;
}
.diss {
    width: 40px;
    height: 40px;
    background-color: orangered;
    border-radius: 50%;
    font-size: 6px;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.line_his {
    width: 150px;
    background-image: linear-gradient(to right, #FF0000, #CD5800, #957200, #5B7D00, #008000);
    height: 3px;
}
.conn {
    width: 40px;
    font-size: 6px;
    display: flex;
    position: relative;
    color: white;
    justify-content: center;
    align-items: center;
    background-color: orange;
    height: 40px;
    border-radius: 50%;
}
.no_data{
    text-align: center;
    display: flex;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    padding-top: 100px;
}
.cry{
    color: orange;
    font-size: 150px;
}





