.filter-date > .form-control{
    height: 40px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 4px 37px 9px rgb(0 0 0 / 3%) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.filter-button{
    height: 40px !important;
    box-shadow: 0px 4px 37px 9px rgba(0, 0, 0, 0.03) !important;
    border-radius: 8px !important;
    border: 1px solid transparent !important;
}

.filter-button:hover{
    background: #F09000 !important;
}

.card{
    /* border-radius: 5px !important; */
    /* box-shadow: 0px 4px 37px 9px rgb(0 0 0 / 3%) !important; */
    overflow: hidden !important;
}

.absolute-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .wrapper {
    width:100%;
    display:flex;
    flex-direction:column;
    height: 400px !important;
  }

  .cool-banner {
    display:flex;
    height:50vh;
  }

  .banner-content {
    display:flex;
    flex-direction: column;
    height:100%;
  }
